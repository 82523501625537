import Button from 'erpcore/components/Button';
import PageContent from 'erpcore/components/Layout/PageContent';
import Listing from 'erpcore/components/Listing';
import {
    getProjectData,
    getUnitsData,
    getUnitsFetching
} from 'erpcore/screens/Projects/Projects.selectors';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import UnitsImport from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsImport';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './ProjectEditMultiEffectUnits.scss';

import restClient from 'erpcore/api/restClient';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import Svg from 'erpcore/components/Svg';
import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import UnitsCreate from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsCreate';
import UnitsDelete from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsDelete';
import UnitsEdit from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsEdit';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const ProjectEditMultiEffectUnits = ({ match }) => {
    const dispatch = useDispatch();
    const projectID = match?.params?.id;
    const reducerName = 'projects';
    const actionName = 'PROJECT_UNITS';
    const listing = useSelector(state => getUnitsData(state));
    const listingFetching = useSelector(state => getUnitsFetching(state));
    const projectIri = `/api/projects/${projectID}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const projectData = dtoForm(projectDataOriginal) || {};
    const unitsData = useSelector(getUnitsData) || [];
    const [exporting, setExporting] = useState(false);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const fetchProjectUnits = params => {
        params = {
            ...params,
            'order_by[id]': 'ASC',
            limit: 999,
            include: 'unitType,building',
            'filters[unitType.project][equals]': projectIri
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: listingActions.START_FETCHING_LISTING,
                promise: { resolve, reject },
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/project/units'
            });
        });
    };

    const exportUnits = () => {
        setExporting(true);
        restClient
            .get(`api/projects/${projectID}/units/export`, { headers: { Accept: 'text/csv' } })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `units-export.csv`);
                link.click();
            })
            .catch(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            })
            .finally(() => setExporting(false));
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'unit_type',
                label: 'Unit Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Unit Type',
                            options: {
                                endpoint: `api/project/unit-types?filters[project.id][equals]=${match?.params?.id}`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'is_available',
                label: 'Availability',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Availability',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Name',
                field: 'name'
            },
            {
                title: 'Building',
                field: 'building'
            },
            {
                title: 'Unit Type',
                field: 'unit_type'
            },
            {
                title: 'Level',
                field: 'level',
                align: 'center'
            },
            {
                title: 'SQF',
                field: 'sqf'
            },
            {
                title: 'Orientation',
                field: 'orientation',
                align: 'center'
            },
            {
                title: 'Listings Status',
                field: 'listings_status',
                align: 'center'
            },
            {
                title: 'Availability',
                field: 'availability',
                align: 'center'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        // Table Data
        if (unitsData?.length) {
            unitsData.map(row => {
                const {
                    id,
                    iri,
                    name,
                    building,
                    unit_type: unitType,
                    level,
                    orientation,
                    is_available: isAvailable,
                    listing_status: listingsStatus
                } = {
                    ...row
                };
                const { square_footage: sqf } = { ...unitType };

                return table.data.push({
                    id,
                    iri,
                    name,
                    building: building?.name || '',
                    unit_type: unitType?.name || '',
                    level,
                    sqf,
                    orientation,
                    listings_status: listingsStatus || '-',
                    availability: isAvailable ? (
                        <Svg icon="markYes" iconColor="apple" />
                    ) : (
                        <Svg icon="markNo" iconColor="tomato" />
                    ),
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <UnitsEdit unitData={row} onFinish={fetchProjectUnits} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <UnitsDelete unitData={row} onFinish={fetchProjectUnits} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent className="units">
                <div className="units__add-and-import">
                    <div className="units__add-and-import-title">
                        <h3>Add or import Units Data</h3>
                        <p>
                            Add as many buildings as you need for your project, along with their
                            floor plans and levels.
                        </p>
                    </div>
                    <div className="units__add-and-import-buttons">
                        <Button
                            label="Export"
                            variation="secondary"
                            size="small"
                            onClick={exportUnits}
                            loading={exporting}
                            disabled={!unitsData?.length}
                        />
                        <UnitsImport runAfterImport={fetchProjectUnits} />
                        <UnitsCreate onFinish={fetchProjectUnits} />
                    </div>
                </div>

                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchProjectUnits(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectUnits.defaultProps = {
    match: {}
};

ProjectEditMultiEffectUnits.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMultiEffectUnits;
