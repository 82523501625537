import React, { useState } from 'react';
import Modal from 'erpcore/components/Modal';
import UnitsCreateEditForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsCreateEditForm';
import Tooltip from 'erpcore/components/Tooltip';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import { diff } from 'deep-object-diff';
import { useDispatch } from 'react-redux';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const UnitsEdit = ({ unitData, onFinish }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const {
        is_available: isAvailable,
        price,
        level,
        unit_type: unitType,
        orientation,
        building,
        name,
        listing_status: listingStatus,
        unit_attributes: unitAttributes = [],
        strata_lot: strataLot,
        amount_public_on_blue: amountPublicOnBlue,
        price_description: priceDescription
    } = {
        ...unitData
    };
    const { amount, currency } = { ...price };
    const initialValues = {
        is_available: isAvailable,
        unitType: unitType?.iri,
        level,
        amount,
        currency,
        orientation,
        building: building?.iri,
        name,
        listing_status: listingStatus,
        unit_attributes: unitAttributes?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        strata_lot: strataLot,
        amount_public_on_blue: amountPublicOnBlue,
        price_description: priceDescription
    };

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        const formDiff = diff(initialValues, formData);

        // if user changed amount, send new value
        // if user deleted amount, send 0
        // if user didn't change amount, send initial value
        const finalAmount = 'amount' in formDiff ? formDiff?.amount || 0 : amount;

        // if user changed currency, send new value
        // if user deleted currency, send 'CAD'
        // if user didn't change currency, send initial value
        const finalCurrency = 'currency' in formDiff ? formDiff?.currency || 'CAD' : currency;

        formDiff.price = {
            amount: finalAmount,
            currency: finalCurrency
        };

        delete formDiff?.currency;
        delete formDiff?.amount;

        // eslint-disable-next-line camelcase
        if ('unit_attributes' in formDiff) {
            formDiff.unit_attributes = cleanRepeaterOutput(formData.unit_attributes, [
                'customIdentifier'
            ]);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_UPDATE_PROJECT_UNIT,
                promise: { resolve, reject },
                iri: unitData?.iri,
                formData: formDiff
            });
        })
            .then(() => {
                handleModal();
                onFinish();
            })
            .catch(err => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: {
                        code: 'projectUnit.nonExistingLevel',
                        detail: err?.errors
                    }
                });
            });
    };

    return (
        <>
            <Tooltip content="Edit unit">
                <button type="button" onClick={handleModal}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal opened={opened} title="Edit unit" variation="medium" onClose={handleModal}>
                <UnitsCreateEditForm
                    onSubmit={onSubmit}
                    submitLabel="Update"
                    onCancel={handleModal}
                    initialValues={initialValues}
                />
            </Modal>
        </>
    );
};

UnitsEdit.defaultProps = {
    unitData: {},
    onFinish: {}
};

UnitsEdit.propTypes = {
    unitData: PropTypes.oneOfType([PropTypes.object]),
    onFinish: PropTypes.func
};

export default UnitsEdit;
